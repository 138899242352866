.tor-header {
  background: linear-gradient( #fd6872, #fc404d);
  border-radius: 4px;
  height: 140px;
  margin-left: 0px !important;;
  text-align: left;
  width: 100%;
  color: #fff;
}

.tor-content {
  margin-top: 20px;
}

.button-bottom-right {
  position:absolute;
  bottom:0px;
  right:10px;
}

.button-back-bottom-right {
  position:absolute;
  bottom:0px;
  right:100px;
}

