.paper-container {
  position: relative;
  height: auto;
  text-align: left;
  background-color: #fff;
  width: calc(100vw - 275px);
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.7);
  padding: 20px;
  overflow-x: hidden;
}

.paper-container button {
  font-family: 'supermarket' !important;
}

.paper-container.full {
  width: calc(100vw - 80px);
  margin-left: 60px;
}

.paper-container>form {
  width: calc(100vw - 190px);
}