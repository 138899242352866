.login-container {
  width: 200px;
  margin: 50px auto;
  text-align: center;
}

.login-button {
  width: auto !important;
  height: 40px !important;
  border: solid 1px #fff !important;
  border-radius: 20px !important;
  color: #fff !important;
  background-color: transparent !important;
}

.login-button:hover {
  background-color: #fff !important;
  color: #ff3d77 !important;
  transition: .5s ease !important;
}

.login-modal-container {
  width: 260px;
  padding-bottom: 40px;
}

.login-modal-container>div:not(:last-child) {
  margin: 5px 5px 15px 5px;
}

.login-modal-container>div>button {
  width: 120px;
  font-size: 18px !important;
  line-height: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 15px !important;
  color: #fff !important;
  font-family: 'supermarket' !important;
  margin-right: 5px !important;
  background: linear-gradient(-45deg, #338aff, #3cf0c5) !important;
}

.login-modal-container>div>button:before {
  content: "";
  position: fixed;
  bottom: 10px;
  right: 15px;
  z-index: -1;
  width: 120px;
  height: 35px;
  background: linear-gradient(-45deg, #338aff, #3cf0c5) !important;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(8px);
  border-radius: 50px;
}

.login-modal-container>div>input, .login-modal-container>div>button {
  border-radius: 50px !important;
}

.login-modal-container>div>button:hover {
  background: linear-gradient(-45deg, #ff3d77, #ffa63d) !important;
}

.login-modal-container>div>button:hover:before {
  background: linear-gradient(-45deg, #ff3d77, #ffa63d) !important;
}