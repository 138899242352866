.outline-button {
  width: auto !important;
  height: 40px !important;
  border: solid 1px #fff !important;
  border-radius: 20px !important;
  color: #fff !important;
  background-color: transparent !important;
  cursor: pointer;
  opacity: 1;
}

.outline-button:hover {
  background-color: #fff !important;
  color: #ff3d77 !important;
  transition: .5s ease !important;
}