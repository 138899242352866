.tab-container>div {
  height: 150vh;
  background-color: #f2f2f2;
}

.project-sidebar {
  margin: 15px 10px 0 10px;
}

.project-sidebar .menu {
  margin: 5px !important;
}

.project-sidebar .menu .item {
  text-align: right;
}

.wrapper-container {
  width: 100%;
  height: 100%;
}

.wrapper-container:after {
  content: "";
  display: table;
  clear: both;
}

.wrapper-container .left {
  float: left;
  width: 250px;
}

.wrapper-container .right {
  float: left;
  width: calc(100% - 250px);
  /* height: 4500px; */
}

/* .fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

div.transition-group {
  position: relative;
}
section.route-section {
position: absolute;
width: 100%;
top: 0;
left: 0;
} */

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}