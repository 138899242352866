.graph {
  width: calc(100vw - 265px);
  margin-left: 5px;
}

.graph.full {
  width: calc(100vw - 20px);
  /* width:"100%" */
}

.graph .left {
  float: left;
  margin: 15px;
  width: calc(100vw - 265px - 400px - 30px);
  height: 40px;
}

.graph .left div {
  vertical-align: top;
}

.graph .left.full {
  width: calc(100vw - 20px - 400px - 30px);
  transition: .5s ease-in;
}

.graph .right {
  float: left;
  width: calc(108vw - 20px - 400px - 30px);
  padding-top: 15px;
  /* height: 150vh */
}

.graph .right .menu .item .padding {
  padding: 5px;
}

.graph:after {
  content: "";
  display: table;
  clear: both;
}

.graph-create-new {
  float: right;
  margin-top: 0 !important;
  padding-right: 200px !important;
}

.graph-create-new .item .button {
  font-family: 'supermarket' !important;
}

.graphbutton-switch {
  width: 160px !important;
  font-family: 'supermarket' !important;
}

.graph-modal-label {
  width: 200px;
  text-align: right;
}

.graph-dropdown .text, .graph-dropdown .item {
  font-family: 'supermarket' !important;
}

.graph-sidebar {
  margin: 15px 10px 0 10px;
}

.graph-sidebar .menu {
  margin: 5px !important;
}

.graph-sidebar .menu .item {
  text-align: right;
}