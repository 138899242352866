.search-result-container {
  text-align: left;
  padding: 20px;
  margin-left: 40px;
}

.search-result-card {
  vertical-align: top;
  display: inline-block;
  background-color: #fff;
  width: 260px;
  height: auto;
  margin: 0 10px 40px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.4);
  padding: 20px;
  text-align: left;
}

.search-result-container .list {
  margin-left: -25px;
  margin-top: 0;
}

.search-result-card .text {
  line-height: 24px;
}

.search-result-card .text span {
  color: rgba(0, 0, 0, 0.7);
}

.search-result-card .button {
  border-radius: 50px !important;
  height: 42px !important;
  line-height: 21px !important;
  font-family: 'supermarket' !important;
  font-size: 18px !important;
}

.search-result-footer {
  width: calc(100% - 40px);
  margin-left: 20px;
}

.search-result-footer .button {
  font-family: 'supermarket' !important;
}
