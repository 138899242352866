.headercontent-title {
  margin: 0 !important;
}

.headercontent-subtitle {
  color: rgba(0, 0, 0, 0.4);
}

.headercontent-menu {
  vertical-align: middle !important;
  box-shadow: none !important;
  min-height: auto !important;
  height: 35px !important;
  margin: 0 !important;
  width: auto !important;
}

.headercontent-menu a {
  padding: 10px !important;
  font-size: 85% !important;
}

.headercontent-menu a i {
  padding-right: 5px;
}