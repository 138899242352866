.tab-container > div {
    height: 150vh;
    background-color: #f2f2f2;
  }
  
  .project-sidebar {
    margin: 15px 10px 0 10px;
  }
  
  .project-sidebar .menu {
    margin: 5px !important;
  }
  
  .project-sidebar .menu .item {
    text-align: right;
  }
  