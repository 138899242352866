.level-container {
  border: none !important;
  margin: 0 !important;
  overflow: hidden;
  box-shadow: none !important;
  padding: 0 !important;
}

.level-container .progress {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 25px !important;
}

.level-container-title.first {
  margin-top: 0;
}

.level-container-title {
  margin-top: 5px;
  font-size: 12px !important;
}

.level-container-title.last {
  margin-bottom: 10px;
}

.level-container-subtitle {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.level-container-content {
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  /*max-height: 60vh;*/
}

.level-container-content div div:last-child {
  padding-bottom: 10px;
}