.header-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 60px;
  width: 100vw;
  background-color: rgba(251, 65, 77, 0.5);
  box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.75);
  text-align: right;
  line-height: 65px;
  padding: 0 20px 0 20px;
}

.header-top-tight {
  margin-right: 10px;
  color: #fff;
  font-size: 20px;
}

.header-logo {
  float: left;
  cursor: pointer;
}

.header-logo .img {
  width: 85px;
}

.header-top-role {
  color: #fff !important;
  font-family: 'supermarket' !important;
  font-size: 20px;
  margin-right: 20px;
  margin-left: 5px;
}