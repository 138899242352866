.step-container {
  position: relative;
  opacity: 1;
  height: auto;
  min-height: 295px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: none;
  margin-bottom: -8px;
  width: 100%;
}

.step-button-container {
  position: relative;
  z-index: 10;
  width: 150px;
  height: 120px;
  border-radius: 20px;
  background: linear-gradient(135deg, rgba(251, 65, 77, 0.2), rgba(253, 180, 185, 0.2));
  cursor: pointer;
  display: inline-block;
  margin: 0 20px 160px 20px;
  vertical-align: top;
}

.step-button-container:hover {
  background: linear-gradient(135deg, rgba(251, 65, 77, 0.4), rgba(253, 180, 185, 0.4));
}

.step-button-container:hover .step-button-inner {
  border-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.85);
  color: rgb(251, 65, 77);
  font-weight: 900;
  box-shadow: 0px 0px 18px -3px rgba(255, 255, 255, 0.75);
  transition: .5s ease;
}

.step-button-container .step-button {
  z-index: 20;
  position: relative;
  top: 10px;
  left: 10px;
  width: 130px;
  height: 100px;
  border-radius: 13px;
  border: none;
  background: linear-gradient(135deg, rgba(251, 65, 77, 0.8), rgba(244, 174, 47, 0.6));
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
}

.step-button-inner {
  text-align: center;
  line-height: 80px;
  z-index: 30;
  position: relative;
  background-color: transparent;
  border-width: 1px;
  border-color: #fff;
  border-style: solid;
  width: 110px;
  height: 80px;
  border-radius: 10px;
  left: 10px;
  top: 10px;
  color: #fff;
  font-size: 18px;
}

.step-button-inner.active::after {
  content: '';
  background: #fff;
  position: absolute;
  top: 48px;
  left: 20px;
  height: 2px;
  width: 65px;
  border-radius: 2px;
}

.step-button-list {
  margin-top: 30px;
  text-align: left;
  width: 180px;
  padding: 0;
}

.step-button-list a {
  display: block;
  height: 25px;
  width: 100%;
  margin: 5px 0 5px 0;
  font-size: 16px;
  border: solid 1px rgba(105, 85, 79, 0.2);
  list-style: none;
  border-radius: 50px;
  line-height: 25px;
  color: rgb(105, 85, 79);
  font-size: 14px;
  font-family: 'supermarket' !important;
}

.step-button-list a.active {
  border-color: rgba(251, 65, 77, 0.7);
  color: rgb(251, 65, 77);
}

.step-button-list a:hover {
  border-color: rgba(251, 65, 77, 0.7);
  color: rgb(251, 65, 77);
}

@keyframes movedown {
  from {
    opacity: 0;
    height: 0%;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}

@-webkit-keyframes movedown {
  from {
    opacity: 0;
    height: 0%;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}