.pmenu-container {
  height: auto;
  width: 100%;
}

.pmenu-content:after {
  content: "";
  display: table;
  clear: both;
}

.pmenu-content .left {
  float: left;
  width: 220px;
}

.pmenu-content>.right {
  float: left;
  width: calc(100% - 220px);
}

.pmenu-content .right .header {
  margin-bottom: 20px;
}

.pmenu-content .right .header .title {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
}

.pmenu-content .right .header .detail {
  line-height: 20px;
}

.pmenu-content .right .header .detail .divider {
  padding: 0 20px 0 20px;
}