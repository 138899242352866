.main-container {
  position: relative;
  width: 100vw;
  height: auto;
  text-align: center;
  overflow: auto;
  margin-top: 60px;
}

.main-header {
  height: 150px;
  text-align: center;
}

.main-header .title {
  margin-left: 260px;
  position: relative;
  padding-top: 10px;
  font-size: 60px;
  color: #fff;
  font-family: 'supermarket';
  text-shadow: 2px 2px #fc404d;
  letter-spacing: 3px;
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
}

.main-subheader {
  text-align: center;
  position: relative;
  margin-left: 380px;
  margin-top: 0;
  height: 60px;
}

.main-subheader .img-container {
  display: inline-block;
}

.main-header .main-subheader>.sub-title {
  display: inline-block;
  color: #fff;
  font-family: 'supermarket';
  font-size: 40px;
  margin-bottom: 40px;
}

.main-card {
  /* height: 60vh; */
}

.main-subheader .img-container .img {
  display: inline-block;
  height: 60px;
}

@media only screen and (max-width: 870px) {
  .main-header .title {
    font-size: 50px;
    margin-left: 0;
  }
  .main-subheader {
    margin-left: 0;
  }
}

@media only screen and (max-width: 580px) {
  .main-header .title {
    font-size: 40px;
    margin-left: 0;
  }
  .main-subheader .img-container {
    display: block;
    margin-left: 0;
  }
  .main-subheader .img-container .img {
    height: 40px;
  }
  .main-header .main-subheader>.sub-title{
    margin-top: 20px;
    font-size: 30px;
  }
}