.search-burn-filter {
    width: calc(100vw - 265px);
    margin-left: 5px;
  }
  
  .search-burn-filter.full {
    width: calc(100vw - 20px);
  }
  
  .search-burn-filter .left {
    float: left;
    margin: 15px;
    width: calc(100vw - 265px - 250px - 80px);
    height: 40px;
  }
  
  .search-burn-filter .left div {
    vertical-align: top;
  }
  
  .search-burn-filter .left.full {
    padding-left: 50px;
    width: calc(100vw - 20px - 250px - 85px);
    transition: .5s ease-in;
  }
  
  .search-burn-filter .right {
    float: left;
    width: 250px;
    padding-top: 10px;
  }
  
  .search-burn-filter .right .menu .item .padding {
    padding: 5px;
  }
  
  .search-burn-filter:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .search-burn-create-new {
    float: right;
    margin-top: 0 !important;
  }
  
  .search-burn-create-new .item .button {
    font-family: 'supermarket' !important;
  }
  
  .button-switch {
    width: 160px !important;
    font-family: 'supermarket' !important;
  }
  
  .search-burn-modal-label {
    width: 200px;
    text-align: right;
  }
  
  .search-burn-dropdown .text, .search-burn-dropdown .item {
    font-family: 'supermarket' !important;
  }