.search-borrow-filter {
  width: calc(100vw - 265px);
  margin-left: 5px;
}

.search-borrow-filter.full {
  width: calc(100vw - 20px);
}

.search-borrow-filter .left {
  float: left;
  margin: 15px;
  width: calc(100vw - 265px - 400px - 30px);
  height: 40px;
}

.search-borrow-filter .left div {
  vertical-align: top;
}

.search-borrow-filter .left.full {
  padding-left: 50px;
  width: calc(100vw - 20px - 400px - 30px);
  transition: .5s ease-in;
}

.search-borrow-filter .right {
  float: left;
  width: 250px;
  padding-top: 10px;
}

.search-borrow-filter .right .menu .item .padding {
  padding: 5px;
}

.search-borrow-filter:after {
  content: "";
  display: table;
  clear: both;
}

.search-borrow-create-new {
  float: right;
  margin-top: 0 !important;
  padding-right: 255px !important;
}

.search-borrow-create-new .item .button {
  font-family: 'supermarket' !important;
}

.button-switch {
  width: 160px !important;
  font-family: 'supermarket' !important;
}

.search-borrow-modal-label {
  width: 200px;
  text-align: right;
}

.search-borrow-dropdown .text, .search-borrow-dropdown .item {
  font-family: 'supermarket' !important;
}