@font-face {
  font-family: 'supermarket';
  src: url(/static/media/supermarket.d09ce847.ttf) format('truetype');
}

body {
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  background: linear-gradient(#fd6872, #fc404d) no-repeat top left !important;
  min-height: 150vh !important;
  max-height: auto !important;
  font-family: 'supermarket' !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
}

.animate-bubble {
  height: 100%;
}

:root {
  --space: 1.5em 0;
  --space: 2em 0;
}


.main-container {
  position: relative;
  width: 100vw;
  height: auto;
  text-align: center;
  overflow: auto;
  margin-top: 60px;
}

.main-header {
  height: 150px;
  text-align: center;
}

.main-header .title {
  margin-left: 260px;
  position: relative;
  padding-top: 10px;
  font-size: 60px;
  color: #fff;
  font-family: 'supermarket';
  text-shadow: 2px 2px #fc404d;
  letter-spacing: 3px;
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
}

.main-subheader {
  text-align: center;
  position: relative;
  margin-left: 380px;
  margin-top: 0;
  height: 60px;
}

.main-subheader .img-container {
  display: inline-block;
}

.main-header .main-subheader>.sub-title {
  display: inline-block;
  color: #fff;
  font-family: 'supermarket';
  font-size: 40px;
  margin-bottom: 40px;
}

.main-card {
  /* height: 60vh; */
}

.main-subheader .img-container .img {
  display: inline-block;
  height: 60px;
}

@media only screen and (max-width: 870px) {
  .main-header .title {
    font-size: 50px;
    margin-left: 0;
  }
  .main-subheader {
    margin-left: 0;
  }
}

@media only screen and (max-width: 580px) {
  .main-header .title {
    font-size: 40px;
    margin-left: 0;
  }
  .main-subheader .img-container {
    display: block;
    margin-left: 0;
  }
  .main-subheader .img-container .img {
    height: 40px;
  }
  .main-header .main-subheader>.sub-title{
    margin-top: 20px;
    font-size: 30px;
  }
}
.login-container {
  width: 200px;
  margin: 50px auto;
  text-align: center;
}

.login-button {
  width: auto !important;
  height: 40px !important;
  border: solid 1px #fff !important;
  border-radius: 20px !important;
  color: #fff !important;
  background-color: transparent !important;
}

.login-button:hover {
  background-color: #fff !important;
  color: #ff3d77 !important;
  transition: .5s ease !important;
}

.login-modal-container {
  width: 260px;
  padding-bottom: 40px;
}

.login-modal-container>div:not(:last-child) {
  margin: 5px 5px 15px 5px;
}

.login-modal-container>div>button {
  width: 120px;
  font-size: 18px !important;
  line-height: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 15px !important;
  color: #fff !important;
  font-family: 'supermarket' !important;
  margin-right: 5px !important;
  background: linear-gradient(-45deg, #338aff, #3cf0c5) !important;
}

.login-modal-container>div>button:before {
  content: "";
  position: fixed;
  bottom: 10px;
  right: 15px;
  z-index: -1;
  width: 120px;
  height: 35px;
  background: linear-gradient(-45deg, #338aff, #3cf0c5) !important;
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(8px);
  border-radius: 50px;
}

.login-modal-container>div>input, .login-modal-container>div>button {
  border-radius: 50px !important;
}

.login-modal-container>div>button:hover {
  background: linear-gradient(-45deg, #ff3d77, #ffa63d) !important;
}

.login-modal-container>div>button:hover:before {
  background: linear-gradient(-45deg, #ff3d77, #ffa63d) !important;
}
.header-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 60px;
  width: 100vw;
  background-color: rgba(251, 65, 77, 0.5);
  box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.75);
  text-align: right;
  line-height: 65px;
  padding: 0 20px 0 20px;
}

.header-top-tight {
  margin-right: 10px;
  color: #fff;
  font-size: 20px;
}

.header-logo {
  float: left;
  cursor: pointer;
}

.header-logo .img {
  width: 85px;
}

.header-top-role {
  color: #fff !important;
  font-family: 'supermarket' !important;
  font-size: 20px;
  margin-right: 20px;
  margin-left: 5px;
}
.outline-button {
  width: auto !important;
  height: 40px !important;
  border: solid 1px #fff !important;
  border-radius: 20px !important;
  color: #fff !important;
  background-color: transparent !important;
  cursor: pointer;
  opacity: 1;
}

.outline-button:hover {
  background-color: #fff !important;
  color: #ff3d77 !important;
  transition: .5s ease !important;
}
.register-container {
  width: 200px;
  margin: 50px auto;
  text-align: center;
}
.search-filter {
  width: calc(100vw - 265px);
  margin-left: 5px;
}

.search-filter.full {
  width: calc(100vw - 20px);
}

.search-filter .left {
  float: left;
  margin: 15px;
  width: calc(100vw - 265px - 400px - 30px);
  height: 40px;
}

.search-filter .left div {
  vertical-align: top;
}

.search-filter .left.full {
  padding-left: 50px;
  width: calc(100vw - 20px - 400px - 30px);
  transition: .5s ease-in;
}

.search-filter .right {
  float: left;
  width: 390px;
  padding-top: 10px;
}

.search-filter .right .menu .item .padding {
  padding: 5px;
}

.search-filter:after {
  content: "";
  display: table;
  clear: both;
}

.search-create-new {
  float: right;
  margin-top: 0 !important;
  padding-right: 200px !important;
}

.search-create-new .item .button {
  font-family: 'supermarket' !important;
}

.button-switch {
  width: 160px !important;
  font-family: 'supermarket' !important;
}

.search-modal-label {
  width: 200px;
  text-align: right;
}

.search-dropdown .text, .search-dropdown .item {
  font-family: 'supermarket' !important;
}
.tor-header {
  background: linear-gradient( #fd6872, #fc404d);
  border-radius: 4px;
  height: 140px;
  margin-left: 0px !important;;
  text-align: left;
  width: 100%;
  color: #fff;
}

.tor-content {
  margin-top: 20px;
}

.button-bottom-right {
  position:absolute;
  bottom:0px;
  right:10px;
}

.button-back-bottom-right {
  position:absolute;
  bottom:0px;
  right:100px;
}


.headercontent-title {
  margin: 0 !important;
}

.headercontent-subtitle {
  color: rgba(0, 0, 0, 0.4);
}

.headercontent-menu {
  vertical-align: middle !important;
  box-shadow: none !important;
  min-height: auto !important;
  height: 35px !important;
  margin: 0 !important;
  width: auto !important;
}

.headercontent-menu a {
  padding: 10px !important;
  font-size: 85% !important;
}

.headercontent-menu a i {
  padding-right: 5px;
}
.search-borrow-filter {
  width: calc(100vw - 265px);
  margin-left: 5px;
}

.search-borrow-filter.full {
  width: calc(100vw - 20px);
}

.search-borrow-filter .left {
  float: left;
  margin: 15px;
  width: calc(100vw - 265px - 400px - 30px);
  height: 40px;
}

.search-borrow-filter .left div {
  vertical-align: top;
}

.search-borrow-filter .left.full {
  padding-left: 50px;
  width: calc(100vw - 20px - 400px - 30px);
  transition: .5s ease-in;
}

.search-borrow-filter .right {
  float: left;
  width: 250px;
  padding-top: 10px;
}

.search-borrow-filter .right .menu .item .padding {
  padding: 5px;
}

.search-borrow-filter:after {
  content: "";
  display: table;
  clear: both;
}

.search-borrow-create-new {
  float: right;
  margin-top: 0 !important;
  padding-right: 255px !important;
}

.search-borrow-create-new .item .button {
  font-family: 'supermarket' !important;
}

.button-switch {
  width: 160px !important;
  font-family: 'supermarket' !important;
}

.search-borrow-modal-label {
  width: 200px;
  text-align: right;
}

.search-borrow-dropdown .text, .search-borrow-dropdown .item {
  font-family: 'supermarket' !important;
}
.tab-container > div {
  height: 150vh;
  background-color: #f2f2f2;
}

.project-sidebar {
  margin: 15px 10px 0 10px;
}

.project-sidebar .menu {
  margin: 5px !important;
}

.project-sidebar .menu .item {
  text-align: right;
}

.paper-container {
  position: relative;
  height: auto;
  text-align: left;
  background-color: #fff;
  width: calc(100vw - 275px);
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.7);
  padding: 20px;
  overflow-x: hidden;
}

.paper-container button {
  font-family: 'supermarket' !important;
}

.paper-container.full {
  width: calc(100vw - 80px);
  margin-left: 60px;
}

.paper-container>form {
  width: calc(100vw - 190px);
}
.sidebar-container {
  width: 100vw;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 50px;
  height: 1800px;
}

.sidebar-button {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
}

.sidebar-button .button:not(:first-child) {
  display: inline-block;
  margin-top: 10px !important;
}

.sidebar-button .button:nth-child(2) {
  animation: fadein 0.3s ease-in;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(3) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(4) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(5) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.search-result-container {
  text-align: left;
  padding: 20px;
  margin-left: 40px;
}

.search-result-card {
  vertical-align: top;
  display: inline-block;
  background-color: #fff;
  width: 260px;
  height: auto;
  margin: 0 10px 40px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.4);
  padding: 20px;
  text-align: left;
}

.search-result-container .list {
  margin-left: -25px;
  margin-top: 0;
}

.search-result-card .text {
  line-height: 24px;
}

.search-result-card .text span {
  color: rgba(0, 0, 0, 0.7);
}

.search-result-card .button {
  border-radius: 50px !important;
  height: 42px !important;
  line-height: 21px !important;
  font-family: 'supermarket' !important;
  font-size: 18px !important;
}

.search-result-footer {
  width: calc(100% - 40px);
  margin-left: 20px;
}

.search-result-footer .button {
  font-family: 'supermarket' !important;
}

.search-burn-filter {
    width: calc(100vw - 265px);
    margin-left: 5px;
  }
  
  .search-burn-filter.full {
    width: calc(100vw - 20px);
  }
  
  .search-burn-filter .left {
    float: left;
    margin: 15px;
    width: calc(100vw - 265px - 250px - 80px);
    height: 40px;
  }
  
  .search-burn-filter .left div {
    vertical-align: top;
  }
  
  .search-burn-filter .left.full {
    padding-left: 50px;
    width: calc(100vw - 20px - 250px - 85px);
    transition: .5s ease-in;
  }
  
  .search-burn-filter .right {
    float: left;
    width: 250px;
    padding-top: 10px;
  }
  
  .search-burn-filter .right .menu .item .padding {
    padding: 5px;
  }
  
  .search-burn-filter:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .search-burn-create-new {
    float: right;
    margin-top: 0 !important;
  }
  
  .search-burn-create-new .item .button {
    font-family: 'supermarket' !important;
  }
  
  .button-switch {
    width: 160px !important;
    font-family: 'supermarket' !important;
  }
  
  .search-burn-modal-label {
    width: 200px;
    text-align: right;
  }
  
  .search-burn-dropdown .text, .search-burn-dropdown .item {
    font-family: 'supermarket' !important;
  }
.tab-container>div {
  height: 150vh;
  background-color: #f2f2f2;
}

.project-sidebar {
  margin: 15px 10px 0 10px;
}

.project-sidebar .menu {
  margin: 5px !important;
}

.project-sidebar .menu .item {
  text-align: right;
}

.wrapper-container {
  width: 100%;
  height: 100%;
}

.wrapper-container:after {
  content: "";
  display: table;
  clear: both;
}

.wrapper-container .left {
  float: left;
  width: 250px;
}

.wrapper-container .right {
  float: left;
  width: calc(100% - 250px);
  /* height: 4500px; */
}

/* .fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

div.transition-group {
  position: relative;
}
section.route-section {
position: absolute;
width: 100%;
top: 0;
left: 0;
} */

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.sidebar-container1 {
  width: 100vw;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 50px;
  height: 3000px;
}

.sidebar-button {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
}

.sidebar-button .button:not(:first-child) {
  display: inline-block;
  margin-top: 10px !important;
}

.sidebar-button .button:nth-child(2) {
  animation: fadein 0.3s ease-in;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(3) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(4) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(5) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}

.project-sidebar .menu .item {
  font-family: 'supermarket' !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.graph {
  width: calc(100vw - 265px);
  margin-left: 5px;
}

.graph.full {
  width: calc(100vw - 20px);
  /* width:"100%" */
}

.graph .left {
  float: left;
  margin: 15px;
  width: calc(100vw - 265px - 400px - 30px);
  height: 40px;
}

.graph .left div {
  vertical-align: top;
}

.graph .left.full {
  width: calc(100vw - 20px - 400px - 30px);
  transition: .5s ease-in;
}

.graph .right {
  float: left;
  width: calc(108vw - 20px - 400px - 30px);
  padding-top: 15px;
  /* height: 150vh */
}

.graph .right .menu .item .padding {
  padding: 5px;
}

.graph:after {
  content: "";
  display: table;
  clear: both;
}

.graph-create-new {
  float: right;
  margin-top: 0 !important;
  padding-right: 200px !important;
}

.graph-create-new .item .button {
  font-family: 'supermarket' !important;
}

.graphbutton-switch {
  width: 160px !important;
  font-family: 'supermarket' !important;
}

.graph-modal-label {
  width: 200px;
  text-align: right;
}

.graph-dropdown .text, .graph-dropdown .item {
  font-family: 'supermarket' !important;
}

.graph-sidebar {
  margin: 15px 10px 0 10px;
}

.graph-sidebar .menu {
  margin: 5px !important;
}

.graph-sidebar .menu .item {
  text-align: right;
}
.tab-container > div {
  height: 150vh;
  background-color: #f2f2f2;
}

.project-sidebar {
  margin: 15px 10px 0 10px;
}

.project-sidebar .menu {
  margin: 5px !important;
}

.project-sidebar .menu .item {
  text-align: right;
}

.sidebar-container {
  width: 100vw;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 50px;
  height: 1800px;
}

.sidebar-button {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
}

.sidebar-button .button:not(:first-child) {
  display: inline-block;
  margin-top: 10px !important;
}

.sidebar-button .button:nth-child(2) {
  animation: fadein 0.3s ease-in;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(3) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(4) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(5) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.level-container {
  border: none !important;
  margin: 0 !important;
  overflow: hidden;
  box-shadow: none !important;
  padding: 0 !important;
}

.level-container .progress {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 25px !important;
}

.level-container-title.first {
  margin-top: 0;
}

.level-container-title {
  margin-top: 5px;
  font-size: 12px !important;
}

.level-container-title.last {
  margin-bottom: 10px;
}

.level-container-subtitle {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.level-container-content {
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  /*max-height: 60vh;*/
}

.level-container-content div div:last-child {
  padding-bottom: 10px;
}
.level-card-container {
  position: relative;
  margin: 15px 0 15px 0;
  z-index: 1;
}

.level-card-container::before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 15px;
  width: 3px;
  height: 100%;
  left: 10px;
}

.level-card-container._orange::before {
  background-color: #f7b147;
}

.level-card-container._green::before {
  background-color: #88c733;
}

.level-card-container._grey::before {
  background-color: grey;
}

.level-card-container._green:last-child::after {
  position: absolute;
  bottom: -20px;
  left: 0;
  text-align: center;
  padding-top: 3px;
  color: #fff;
  font-size: 12px;
  content: '0';
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #88c733;
}

.level-card-circle {
  color: #fff !important;
}

.level-card-circle._orange {
  background: #f7b147 !important;
}

.level-card-circle._green {
  background: #88c733 !important;
}

.level-card-circle._grey {
  background: grey !important;
}

.level-card-header {
  position: relative;
  margin-left: 40px;
  margin-top: -30px;
  min-width: 150px;
  max-width: 150px;
  height: 30px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  line-height: 33px;
  padding-left: 10px;
  color: #fff;
  font-size: 12px;
}

.level-card-header._orange {
  background-color: #f7b147;
}

.level-card-header._green {
  background-color: #88c733;
}

.level-card-header._grey {
  background-color: grey;
}

.level-card-header::before {
  content: '';
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
  left: -7px;
  border-width: 5px 8px 5px 0;
}

.level-card-header._orange::before {
  border-color: transparent #f7b147 transparent transparent;
}

.level-card-header._green::before {
  border-color: transparent #88c733 transparent transparent;
}

.level-card-header._grey::before {
  border-color: transparent grey transparent transparent;
}

.level-card-content {
  padding: 18px 5px 15px 10px;
  min-width: 150px;
  max-width: 150px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: 40px;
}

.level-card-header._orange+.level-card-content {
  border-color: #f7b147;
}

.level-card-header._green+.level-card-content {
  border-color: #88c733;
}

.level-card-header._grey+.level-card-content {
  border-color: grey;
}
.level-card-content-grid-row {
  padding: 0 5px 0 5px !important;
  margin: 0 !important;
}

.level-card-content-grid-column {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding: 0 !important;
}

.level-card-content-grid-column.padding {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.step-container {
  position: relative;
  opacity: 1;
  height: auto;
  min-height: 295px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: none;
  margin-bottom: -8px;
  width: 100%;
}

.step-button-container {
  position: relative;
  z-index: 10;
  width: 150px;
  height: 120px;
  border-radius: 20px;
  background: linear-gradient(135deg, rgba(251, 65, 77, 0.2), rgba(253, 180, 185, 0.2));
  cursor: pointer;
  display: inline-block;
  margin: 0 20px 160px 20px;
  vertical-align: top;
}

.step-button-container:hover {
  background: linear-gradient(135deg, rgba(251, 65, 77, 0.4), rgba(253, 180, 185, 0.4));
}

.step-button-container:hover .step-button-inner {
  border-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.85);
  color: rgb(251, 65, 77);
  font-weight: 900;
  box-shadow: 0px 0px 18px -3px rgba(255, 255, 255, 0.75);
  transition: .5s ease;
}

.step-button-container .step-button {
  z-index: 20;
  position: relative;
  top: 10px;
  left: 10px;
  width: 130px;
  height: 100px;
  border-radius: 13px;
  border: none;
  background: linear-gradient(135deg, rgba(251, 65, 77, 0.8), rgba(244, 174, 47, 0.6));
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
}

.step-button-inner {
  text-align: center;
  line-height: 80px;
  z-index: 30;
  position: relative;
  background-color: transparent;
  border-width: 1px;
  border-color: #fff;
  border-style: solid;
  width: 110px;
  height: 80px;
  border-radius: 10px;
  left: 10px;
  top: 10px;
  color: #fff;
  font-size: 18px;
}

.step-button-inner.active::after {
  content: '';
  background: #fff;
  position: absolute;
  top: 48px;
  left: 20px;
  height: 2px;
  width: 65px;
  border-radius: 2px;
}

.step-button-list {
  margin-top: 30px;
  text-align: left;
  width: 180px;
  padding: 0;
}

.step-button-list a {
  display: block;
  height: 25px;
  width: 100%;
  margin: 5px 0 5px 0;
  font-size: 16px;
  border: solid 1px rgba(105, 85, 79, 0.2);
  list-style: none;
  border-radius: 50px;
  line-height: 25px;
  color: rgb(105, 85, 79);
  font-size: 14px;
  font-family: 'supermarket' !important;
}

.step-button-list a.active {
  border-color: rgba(251, 65, 77, 0.7);
  color: rgb(251, 65, 77);
}

.step-button-list a:hover {
  border-color: rgba(251, 65, 77, 0.7);
  color: rgb(251, 65, 77);
}

@keyframes movedown {
  from {
    opacity: 0;
    height: 0%;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}
.pmenu-container {
  height: auto;
  width: 100%;
}

.pmenu-content:after {
  content: "";
  display: table;
  clear: both;
}

.pmenu-content .left {
  float: left;
  width: 220px;
}

.pmenu-content>.right {
  float: left;
  width: calc(100% - 220px);
}

.pmenu-content .right .header {
  margin-bottom: 20px;
}

.pmenu-content .right .header .title {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
}

.pmenu-content .right .header .detail {
  line-height: 20px;
}

.pmenu-content .right .header .detail .divider {
  padding: 0 20px 0 20px;
}
.tab-container > div {
  height: 150vh;
  background-color: #f2f2f2;
}

.project-sidebar {
  margin: 15px 10px 0 10px;
}

.project-sidebar .menu {
  margin: 5px !important;
}

.project-sidebar .menu .item {
  text-align: right;
}

.sidebar-container {
  width: 100vw;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 50px;
  height: 1800px;
}

.sidebar-button {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
}

.sidebar-button .button:not(:first-child) {
  display: inline-block;
  margin-top: 10px !important;
}

.sidebar-button .button:nth-child(2) {
  animation: fadein 0.3s ease-in;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(3) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(4) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(5) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tab-container > div {
  height: 150vh;
  background-color: #f2f2f2;
}

.project-sidebar {
  margin: 15px 10px 0 10px;
}

.project-sidebar .menu {
  margin: 5px !important;
}

.project-sidebar .menu .item {
  text-align: right;
}

.sidebar-container {
  width: 100vw;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 50px;
  height: 1800px;
}

.sidebar-button {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
}

.sidebar-button .button:not(:first-child) {
  display: inline-block;
  margin-top: 10px !important;
}

.sidebar-button .button:nth-child(2) {
  animation: fadein 0.3s ease-in;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(3) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(4) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(5) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tab-container > div {
    height: 150vh;
    background-color: #f2f2f2;
  }
  
  .project-sidebar {
    margin: 15px 10px 0 10px;
  }
  
  .project-sidebar .menu {
    margin: 5px !important;
  }
  
  .project-sidebar .menu .item {
    text-align: right;
  }
  
.sidebar-container {
  width: 100vw;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 50px;
  height: 1800px;
}

.sidebar-button {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
}

.sidebar-button .button:not(:first-child) {
  display: inline-block;
  margin-top: 10px !important;
}

.sidebar-button .button:nth-child(2) {
  animation: fadein 0.3s ease-in;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(3) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(4) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(5) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
