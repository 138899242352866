.level-card-content-grid-row {
  padding: 0 5px 0 5px !important;
  margin: 0 !important;
}

.level-card-content-grid-column {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding: 0 !important;
}

.level-card-content-grid-column.padding {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding-left: 5px !important;
  padding-right: 5px !important;
}