.level-card-container {
  position: relative;
  margin: 15px 0 15px 0;
  z-index: 1;
}

.level-card-container::before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 15px;
  width: 3px;
  height: 100%;
  left: 10px;
}

.level-card-container._orange::before {
  background-color: #f7b147;
}

.level-card-container._green::before {
  background-color: #88c733;
}

.level-card-container._grey::before {
  background-color: grey;
}

.level-card-container._green:last-child::after {
  position: absolute;
  bottom: -20px;
  left: 0;
  text-align: center;
  padding-top: 3px;
  color: #fff;
  font-size: 12px;
  content: '0';
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #88c733;
}

.level-card-circle {
  color: #fff !important;
}

.level-card-circle._orange {
  background: #f7b147 !important;
}

.level-card-circle._green {
  background: #88c733 !important;
}

.level-card-circle._grey {
  background: grey !important;
}

.level-card-header {
  position: relative;
  margin-left: 40px;
  margin-top: -30px;
  min-width: 150px;
  max-width: 150px;
  height: 30px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  line-height: 33px;
  padding-left: 10px;
  color: #fff;
  font-size: 12px;
}

.level-card-header._orange {
  background-color: #f7b147;
}

.level-card-header._green {
  background-color: #88c733;
}

.level-card-header._grey {
  background-color: grey;
}

.level-card-header::before {
  content: '';
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
  left: -7px;
  border-width: 5px 8px 5px 0;
}

.level-card-header._orange::before {
  border-color: transparent #f7b147 transparent transparent;
}

.level-card-header._green::before {
  border-color: transparent #88c733 transparent transparent;
}

.level-card-header._grey::before {
  border-color: transparent grey transparent transparent;
}

.level-card-content {
  padding: 18px 5px 15px 10px;
  min-width: 150px;
  max-width: 150px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: 40px;
}

.level-card-header._orange+.level-card-content {
  border-color: #f7b147;
}

.level-card-header._green+.level-card-content {
  border-color: #88c733;
}

.level-card-header._grey+.level-card-content {
  border-color: grey;
}