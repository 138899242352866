.sidebar-container {
  width: 100vw;
  position: relative;
  background-color: #f2f2f2;
  padding-top: 50px;
  height: 1800px;
}

.sidebar-button {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
}

.sidebar-button .button:not(:first-child) {
  display: inline-block;
  margin-top: 10px !important;
}

.sidebar-button .button:nth-child(2) {
  animation: fadein 0.3s ease-in;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(3) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(4) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.sidebar-button .button:nth-child(5) {
  animation: fadein 0.3s ease-in;
  animation-delay: 0.6s;
  animation-fill-mode: both;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}