@font-face {
  font-family: 'supermarket';
  src: url('supermarket.ttf') format('truetype');
}

body {
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  background: linear-gradient(#fd6872, #fc404d) no-repeat top left !important;
  min-height: 150vh !important;
  max-height: auto !important;
  font-family: 'supermarket' !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
}

.animate-bubble {
  height: 100%;
}

:root {
  --space: 1.5em 0;
  --space: 2em 0;
}

